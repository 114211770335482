/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Custom API Docs", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docusaurus.lindarobledo.com/"
  }, "Redoc + Docusaurus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.shins.lindarobledo.com/#quick-start"
  }, "Shins")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/docs-audit"
  }, "Docs Audit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lrobledo.github.io/style-guide/"
  }, "Style Guide")), "\n"), "\n", React.createElement(_components.p, null, "For something a bit more formal,\nyou can also download a PDF version of my ", React.createElement(_components.a, {
    href: "/linda-robledo-resume.pdf"
  }, "resume"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
